
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { TimingPlan } from '@/types/irrigation'
import { timeIrrigationDesc, intellectIrrigationDesc } from '@/utils/config'

@Component
export default class IrrigationTimingDetail extends Vue {
  @Prop() private showDialog!: boolean
  @Prop() private id!: string
  private info: TimingPlan = {
    planType: '1',
    planName: '',
    areaId: '',
    projectId: '',
    dateRange: [],
    executeTime: '',

    concurrentNum: '',
    intervalDays: '',
    keepLength: '',
    drought: ''
  }

  @Watch('id')
  getIsShow (val: string) {
    console.log(val)
    this.getPlanDetail(val)
  }

  get timeIrrigationDesc () {
    return timeIrrigationDesc
  }

  get intellectIrrigationDesc () {
    return intellectIrrigationDesc
  }

  getPlanDetail (id: string) {
    this.$axios.get(this.$apis.irrigation.selectIrrigationPlanByPlanId, {
      planId: id
    }).then((res) => {
      this.info = res
    })
  }

  close () {
    this.$emit('update:showDialog', false)
  }
}


import { Vue, Component } from 'vue-property-decorator'
import IrrigationPlanAdd from './IrrigationPlanAdd.vue'
import IrrigationPlanDetail from './IrrigationPlanDetail.vue'
import { TimingPlan } from '@/types/irrigation'

@Component({
  components: {
    IrrigationPlanAdd,
    IrrigationPlanDetail
  },
  computed: {
  }
})
export default class IrrigationTimingPlan extends Vue {
  private tableData: TimingPlan[] = []
  page = 1
  size = 10
  total = 0
  loading = true
  showDialog = false
  showDialog1 = false
  id = ''
  id1 = ''
  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.irrigation.selectIrrigationPlanByPage, {
      page: this.page,
      size: this.size,
      projectId: this.$route.query.projectId
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
      this.tableData.forEach((item: TimingPlan) => {
        item.status = item.planStatus !== 'N'
      })
    }).finally(() => {
      this.loading = false
    })
  }

  change (id: string) {
    this.$axios.post(this.$apis.irrigation.updateIrrigationPlanStatus, {
      planId: id
    }).then(() => {
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.loadData()
    })
  }

  onAdd () {
    this.showDialog = true
  }

  onUpdate (id: string) {
    this.showDialog = true
    this.id = id
  }

  success () {
    this.id = ''
    this.loadData()
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.irrigation.deleteIrrigationPlan, {
        planId: id
      }).then(() => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.page = 1
        this.loadData()
      })
    })
  }

  onDetail (id: string) {
    this.id1 = id
    this.showDialog1 = true
  }
}


import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { pInteger } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { TimingPlan } from '@/types/irrigation'
import { timeIrrigationDesc, intellectIrrigationDesc } from '@/utils/config'

@Component
export default class IrrigationPlanAdd extends Vue {
  @Prop() private showDialog!: boolean
  @Prop() private id!: string
  // 正整数校验，小于10
  numLessTen = function (rule: object, value: string, callback: Function) {
    const reg = /^[1-9][0-9]*$/
    if (!reg.test(value) || Number(value) < 2 || Number(value) > 10) {
      return callback(new Error('请输入2到10数值'))
    } else {
      return callback()
    }
  }

  private info: TimingPlan = {
    planType: '1',
    planName: '',
    areaId: '',
    projectId: '',
    dateRange: [],
    executeTime: '',

    concurrentNum: '',
    intervalDays: '',
    keepLength: '',
    drought: ''
  }

  droughtRules = function (rule: object, value: string, callback: Function) {
    const reg = /^([1-9][0-9]*|[0-9])(\.\d)?$/
    if (value && !reg.test(value)) {
      return callback(new Error('请输入一位以内的小数或正整数'))
    } else if (Number(value) > 100) {
      return callback(new Error('请输入小于等于100的数字'))
    } else {
      return callback()
    }
  }

  private rules = {
    planName: [
      { required: true, message: '请输入计划名称', trigger: ['blur', 'change'] }
    ],
    areaId: [
      { required: true, message: '请选择喷灌区域', trigger: ['blur', 'change'] }
    ],
    dateRange: [
      { required: true, message: '请选择计划时间段', trigger: ['blur', 'change'] }
    ],
    executeTime: [
      { required: true, message: '请选择开启时间', trigger: ['blur', 'change'] }
    ],
    concurrentNum: [
      { required: true, message: '请输入电磁阀一次同时开启的个数，范围1个到3个', trigger: ['blur', 'change'] }
    ],
    intervalDays: [
      { required: true, message: '请输入喷灌频率', trigger: ['blur', 'change'] },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ],
    keepLength: [
      { required: true, message: '请输入电磁阀开启时长，时长范围2分钟到10分钟', trigger: ['blur', 'change'] },
      { validator: this.numLessTen, trigger: ['blur', 'change'] }
    ],
    drought: [
      { required: true, message: '请输入喷灌启动阈值', trigger: ['blur'] },
      { validator: this.droughtRules, trigger: ['blur', 'change'] }
    ]
  }

  private areaList = []
  private submitShow = false

  get timeIrrigationDesc () {
    return timeIrrigationDesc
  }

  get intellectIrrigationDesc () {
    return intellectIrrigationDesc
  }

  @Watch('id')
  getIsShow (val: string) {
    if (val) {
      this.getPlanDetail(val)
    }
  }

  created () {
    this.getArea()
  }

  // 清空状态和数值
  change (val: string) {
    ;(this.$refs.info as ElForm).resetFields()
    this.info.planType = val
  }

  getPlanDetail (id: string) {
    this.$axios.get(this.$apis.irrigation.selectIrrigationPlanByPlanId, {
      planId: id
    }).then((res) => {
      this.change(res.planType)
      this.info = {
        planType: res.planType,
        planId: res.planId,
        planName: res.planName,
        areaId: res.areaId,
        projectId: res.projectId,
        dateRange: [res.startDate, res.endDate],
        executeTime: res.executeTime,
        drought: res.drought,
        concurrentNum: res.concurrentNum,
        intervalDays: res.intervalDays,
        keepLength: res.keepLength
      }
    })
  }

  getArea () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationAreaByPage).then((res) => {
      this.areaList = res.list || []
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const info = {
          projectId: this.$route.query.projectId,
          planName: this.info.planName,
          areaId: this.info.areaId,
          planType: this.info.planType,
          startDate: this.info.dateRange[0],
          endDate: this.info.dateRange[1],
          executeTime: this.info.executeTime,
          concurrentNum: this.info.concurrentNum,
          intervalDays: this.info.intervalDays,
          keepLength: this.info.keepLength,
          planId: this.info.planId,
          drought: this.info.drought
        }
        const url = this.id ? this.$apis.irrigation.updateIrrigationPlan : this.$apis.irrigation.insertIrrigationPlan
        this.$axios.post(url, {
          ...info
        }).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.goBack()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  goBack () {
    this.info = {
      planType: '1',
      planName: '',
      areaId: '',
      projectId: '',
      dateRange: [],
      executeTime: '',
      concurrentNum: '',
      intervalDays: '',
      keepLength: '',
      drought: ''
    }
    this.$nextTick(() => {
      ;(this.$refs.info as ElForm).clearValidate()
    })
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }
}
